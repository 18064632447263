/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Hace unos días que me lo leí pero no había podido escribir aún sobre él. Ya ha salido el ", React.createElement(_components.a, {
    href: "http://bitacora.lasindias.com/el-modo-de-produccion-p2p-un-manifiesto-indiano/"
  }, "manifiesto indiano"), " que explica el modo de producción P2P y la revolución que puede suponer. En el ultimo post ya hablé sobre este tipo de producción y tengo pensado seguir haciéndolo, es la consecuencia de ir absorbiendo las ideas indianas que exponen en su blog. El texto es muy explicativo aunque posiblemente un poco complejo de entender si no conoces el contexto de ", React.createElement(_components.a, {
    href: "http://lasindias.com/"
  }, "Las Indias"), ", por eso recomiendo echar un vistazo a su blog y leer ", React.createElement(_components.a, {
    href: "http://lasindias.org/"
  }, "la trilogía de las redes"), " para entender todo el conjunto."), "\n", React.createElement(_components.p, null, "Sólo me queda mandar desde aquí ánimos a todo el que quiera plantearse este tipo de producción, más propia de los nuevos tiempos y agradecer a Las Indias su trabajo por darme nuevas ideas con las que formar las mías propias."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
